import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const WebViewContainer = styled.div`
  background-color: #f3f4f6;
  min-height: 100vh;
  padding: 20px;
`;

const HeaderContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
`;

const PageTitle = styled.h1`
  color: black;
  text-align: center;
  margin: 0;
`;

function App() {
  const [loyaltyData, setLoyaltyData] = useState(null);

  useEffect(() => {
    const fetchLoyaltyData = async () => {
      try {
        // Use the environment variable here
        const workerUrl = process.env.REACT_APP_WORKER_URL;
        console.log(workerUrl);
        if (!workerUrl) {
          throw new Error('Worker URL is not defined in environment variables');
        }
        const response = await axios.get(`${workerUrl}/loyalty`);
        console.log(response.data);
        setLoyaltyData(response.data);
      } catch (error) {
        console.error('Error fetching loyalty data:', error);
      }
    };

    fetchLoyaltyData();
  }, []);

  return (
    <WebViewContainer>
      <HeaderContainer>
        <PageTitle>Loyalty Program</PageTitle>
      </HeaderContainer>
      {loyaltyData ? (
        <div>
          <p>Loyalty ID: {loyaltyData.loyalty_id}</p>
          <h2>Available Coupons:</h2>
          {loyaltyData && JSON.stringify(loyaltyData)}
          <ul>
            {loyaltyData.coupons.map((coupon, index) => (
              <li key={index}>{coupon.label} - Code: {coupon.code}</li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Loading loyalty data...</p>
      )}
    </WebViewContainer>
  );
}

export default App;
